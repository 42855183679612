import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIEndPoint } from "src/app/core/constants/api-endpoints";
@Injectable({
  providedIn: "root",
})
export class FileCategorizationService {
  BASE_CATEGORIZATION_URL:string = APIEndPoint.ENDPOINT_URL + "/api/references/categorization";

  constructor(private http: HttpClient) {}

  getCreditCategories() {
    return this.http.get(
      this.BASE_CATEGORIZATION_URL +
        "/main_categorization_flags"
    );
  }

  getCategorizedFlags() {
    return this.http.get(
      this.BASE_CATEGORIZATION_URL + "/flags"
    );
  }

  getCategorizationCourseProvider() {
    return this.http.get(
      this.BASE_CATEGORIZATION_URL + "/providers"
    );
  }

  saveCategorization(file: any) {
    return this.http.post(
      this.BASE_CATEGORIZATION_URL,
      file
    );
  }

  updateCategorization(id: any, payload: any) {
    return this.http.patch(
      this.BASE_CATEGORIZATION_URL + "/" + id,
      payload
    );
  }

  getPreview(categorizationId: number) {
    return this.http.get(
      `${this.BASE_CATEGORIZATION_URL}/${categorizationId}/preview`,
      { responseType: "blob" }
    );
}

  getCategorizationById(id: any) {
    return this.http.get(
      this.BASE_CATEGORIZATION_URL + "/" + id
    );
  }
}
